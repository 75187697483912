import mixpanel from 'mixpanel-browser'
import * as amplitude from '@amplitude/analytics-browser'
import useConfig from './useConfig'
interface iTrack {
  event: string
  properties?: any
}

const useAnalytics = () => {
  const config = useConfig()

  const init = () => {
    try {
      mixpanel.init(config.public.ANALYTICS_MIXPANEL_ID, {
        track_pageview: true,
      })
    } catch (err) {
      console.log('[ERROR] with useAnalytics.init')
    }
  }

  const identify = ({ id }: { id: string | undefined }) => {
    try {
      mixpanel.identify(id)
      // adding this here since it's actually somewhat like an identify command
      amplitude.init(config.public.ANALYTICS_AMPLITUDE_ID, id)
      // const identifyEvent = new amplitude.Identify()
      // amplitude.identify(identifyEvent)
      // identifyEvent.set('location', 'LAX')
      // amplitude.identify(id)
    } catch (err) {
      console.log('[ERROR] with useAnalytics.identify')
    }
  }

  const track = ({ event, properties }: iTrack) => {
    try {
      mixpanel.track(event, properties)
      amplitude.track(event, properties)
    } catch (err) {
      console.log('[ERROR] with useAnalytics.identify')
    }
  }

  return {
    init,
    identify,
    track,
  }
}

export default useAnalytics
